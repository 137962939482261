// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-materia-01-protocol-overview-01-how-materia-works-md": () => import("./../../../src/pages/docs/materia/01-protocol-overview/01-how-materia-works.md" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-01-how-materia-works-md" */),
  "component---src-pages-docs-materia-01-protocol-overview-02-ecosystem-participants-md": () => import("./../../../src/pages/docs/materia/01-protocol-overview/02-ecosystem-participants.md" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-02-ecosystem-participants-md" */),
  "component---src-pages-docs-materia-01-protocol-overview-03-smart-contracts-md": () => import("./../../../src/pages/docs/materia/01-protocol-overview/03-smart-contracts.md" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-03-smart-contracts-md" */),
  "component---src-pages-docs-materia-01-protocol-overview-04-glossary-md": () => import("./../../../src/pages/docs/materia/01-protocol-overview/04-glossary.md" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-04-glossary-md" */),
  "component---src-pages-docs-materia-01-protocol-overview-index-js": () => import("./../../../src/pages/docs/materia/01-protocol-overview/index.js" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-index-js" */),
  "component---src-pages-docs-materia-01-protocol-overview-reference-advanced-topics-js": () => import("./../../../src/pages/docs/materia/01-protocol-overview/reference/Advanced-topics.js" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-reference-advanced-topics-js" */),
  "component---src-pages-docs-materia-01-protocol-overview-reference-core-concepts-js": () => import("./../../../src/pages/docs/materia/01-protocol-overview/reference/Core-concepts.js" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-reference-core-concepts-js" */),
  "component---src-pages-docs-materia-01-protocol-overview-reference-whitepaper-js": () => import("./../../../src/pages/docs/materia/01-protocol-overview/reference/Whitepaper.js" /* webpackChunkName: "component---src-pages-docs-materia-01-protocol-overview-reference-whitepaper-js" */),
  "component---src-pages-docs-materia-02-core-concepts-01-swaps-md": () => import("./../../../src/pages/docs/materia/02-core-concepts/01-swaps.md" /* webpackChunkName: "component---src-pages-docs-materia-02-core-concepts-01-swaps-md" */),
  "component---src-pages-docs-materia-02-core-concepts-02-pools-md": () => import("./../../../src/pages/docs/materia/02-core-concepts/02-pools.md" /* webpackChunkName: "component---src-pages-docs-materia-02-core-concepts-02-pools-md" */),
  "component---src-pages-docs-materia-02-core-concepts-03-flash-swaps-md": () => import("./../../../src/pages/docs/materia/02-core-concepts/03-flash-swaps.md" /* webpackChunkName: "component---src-pages-docs-materia-02-core-concepts-03-flash-swaps-md" */),
  "component---src-pages-docs-materia-02-core-concepts-index-js": () => import("./../../../src/pages/docs/materia/02-core-concepts/index.js" /* webpackChunkName: "component---src-pages-docs-materia-02-core-concepts-index-js" */),
  "component---src-pages-docs-materia-02-core-concepts-reference-integration-quick-start-js": () => import("./../../../src/pages/docs/materia/02-core-concepts/reference/Integration-quick-start.js" /* webpackChunkName: "component---src-pages-docs-materia-02-core-concepts-reference-integration-quick-start-js" */),
  "component---src-pages-docs-materia-03-advanced-topics-01-fees-md": () => import("./../../../src/pages/docs/materia/03-advanced-topics/01-fees.md" /* webpackChunkName: "component---src-pages-docs-materia-03-advanced-topics-01-fees-md" */),
  "component---src-pages-docs-materia-03-advanced-topics-02-ethitem-md": () => import("./../../../src/pages/docs/materia/03-advanced-topics/02-ethitem.md" /* webpackChunkName: "component---src-pages-docs-materia-03-advanced-topics-02-ethitem-md" */),
  "component---src-pages-docs-materia-03-advanced-topics-index-js": () => import("./../../../src/pages/docs/materia/03-advanced-topics/index.js" /* webpackChunkName: "component---src-pages-docs-materia-03-advanced-topics-index-js" */),
  "component---src-pages-docs-materia-05-javascript-sdk-01-quick-start-md": () => import("./../../../src/pages/docs/materia/05-javascript-SDK/01-quick-start.md" /* webpackChunkName: "component---src-pages-docs-materia-05-javascript-sdk-01-quick-start-md" */),
  "component---src-pages-docs-materia-05-javascript-sdk-02-fetching-data-md": () => import("./../../../src/pages/docs/materia/05-javascript-SDK/02-fetching-data.md" /* webpackChunkName: "component---src-pages-docs-materia-05-javascript-sdk-02-fetching-data-md" */),
  "component---src-pages-docs-materia-05-javascript-sdk-03-pricing-md": () => import("./../../../src/pages/docs/materia/05-javascript-SDK/03-pricing.md" /* webpackChunkName: "component---src-pages-docs-materia-05-javascript-sdk-03-pricing-md" */),
  "component---src-pages-docs-materia-05-javascript-sdk-04-trading-md": () => import("./../../../src/pages/docs/materia/05-javascript-SDK/04-trading.md" /* webpackChunkName: "component---src-pages-docs-materia-05-javascript-sdk-04-trading-md" */),
  "component---src-pages-docs-materia-05-javascript-sdk-05-getting-pair-addresses-md": () => import("./../../../src/pages/docs/materia/05-javascript-SDK/05-getting-pair-addresses.md" /* webpackChunkName: "component---src-pages-docs-materia-05-javascript-sdk-05-getting-pair-addresses-md" */),
  "component---src-pages-docs-materia-05-javascript-sdk-index-js": () => import("./../../../src/pages/docs/materia/05-javascript-SDK/index.js" /* webpackChunkName: "component---src-pages-docs-materia-05-javascript-sdk-index-js" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-01-quick-start-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/01-quick-start.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-01-quick-start-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-02-trading-from-a-smart-contract-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/02-trading-from-a-smart-contract.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-02-trading-from-a-smart-contract-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-03-providing-liquidity-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/03-providing-liquidity.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-03-providing-liquidity-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-04-building-an-oracle-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/04-building-an-oracle.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-04-building-an-oracle-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-05-using-flash-swaps-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/05-using-flash-swaps.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-05-using-flash-swaps-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-06-getting-pair-addresses-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/06-getting-pair-addresses.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-06-getting-pair-addresses-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-07-supporting-meta-transactions-md": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/07-supporting-meta-transactions.md" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-07-supporting-meta-transactions-md" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-index-js": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/index.js" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-index-js" */),
  "component---src-pages-docs-materia-06-smart-contract-integration-reference-smart-contract-architecture-js": () => import("./../../../src/pages/docs/materia/06-smart-contract-integration/reference/smart-contract-architecture.js" /* webpackChunkName: "component---src-pages-docs-materia-06-smart-contract-integration-reference-smart-contract-architecture-js" */),
  "component---src-pages-docs-materia-07-interface-integration-01-custom-interface-linking-md": () => import("./../../../src/pages/docs/materia/07-interface-integration/01-custom-interface-linking.md" /* webpackChunkName: "component---src-pages-docs-materia-07-interface-integration-01-custom-interface-linking-md" */),
  "component---src-pages-docs-materia-07-interface-integration-02-iframe-integration-md": () => import("./../../../src/pages/docs/materia/07-interface-integration/02-iframe-integration.md" /* webpackChunkName: "component---src-pages-docs-materia-07-interface-integration-02-iframe-integration-md" */),
  "component---src-pages-docs-materia-07-interface-integration-index-js": () => import("./../../../src/pages/docs/materia/07-interface-integration/index.js" /* webpackChunkName: "component---src-pages-docs-materia-07-interface-integration-index-js" */),
  "component---src-pages-docs-materia-08-sdk-01-getting-started-md": () => import("./../../../src/pages/docs/materia/08-SDK/01-getting-started.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-01-getting-started-md" */),
  "component---src-pages-docs-materia-08-sdk-02-token-md": () => import("./../../../src/pages/docs/materia/08-SDK/02-token.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-02-token-md" */),
  "component---src-pages-docs-materia-08-sdk-03-pair-md": () => import("./../../../src/pages/docs/materia/08-SDK/03-pair.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-03-pair-md" */),
  "component---src-pages-docs-materia-08-sdk-04-route-md": () => import("./../../../src/pages/docs/materia/08-SDK/04-route.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-04-route-md" */),
  "component---src-pages-docs-materia-08-sdk-05-trade-md": () => import("./../../../src/pages/docs/materia/08-SDK/05-trade.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-05-trade-md" */),
  "component---src-pages-docs-materia-08-sdk-06-fractions-md": () => import("./../../../src/pages/docs/materia/08-SDK/06-fractions.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-06-fractions-md" */),
  "component---src-pages-docs-materia-08-sdk-07-fetcher-md": () => import("./../../../src/pages/docs/materia/08-SDK/07-fetcher.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-07-fetcher-md" */),
  "component---src-pages-docs-materia-08-sdk-08-other-exports-md": () => import("./../../../src/pages/docs/materia/08-SDK/08-other-exports.md" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-08-other-exports-md" */),
  "component---src-pages-docs-materia-08-sdk-index-js": () => import("./../../../src/pages/docs/materia/08-SDK/index.js" /* webpackChunkName: "component---src-pages-docs-materia-08-sdk-index-js" */),
  "component---src-pages-docs-materia-09-smart-contracts-01-factory-md": () => import("./../../../src/pages/docs/materia/09-smart-contracts/01-factory.md" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-01-factory-md" */),
  "component---src-pages-docs-materia-09-smart-contracts-02-pair-md": () => import("./../../../src/pages/docs/materia/09-smart-contracts/02-pair.md" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-02-pair-md" */),
  "component---src-pages-docs-materia-09-smart-contracts-03-pair-erc-20-md": () => import("./../../../src/pages/docs/materia/09-smart-contracts/03-pair-erc-20.md" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-03-pair-erc-20-md" */),
  "component---src-pages-docs-materia-09-smart-contracts-04-library-md": () => import("./../../../src/pages/docs/materia/09-smart-contracts/04-library.md" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-04-library-md" */),
  "component---src-pages-docs-materia-09-smart-contracts-05-orchestrator-md": () => import("./../../../src/pages/docs/materia/09-smart-contracts/05-orchestrator.md" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-05-orchestrator-md" */),
  "component---src-pages-docs-materia-09-smart-contracts-06-common-errors-md": () => import("./../../../src/pages/docs/materia/09-smart-contracts/06-common-errors.md" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-06-common-errors-md" */),
  "component---src-pages-docs-materia-09-smart-contracts-index-js": () => import("./../../../src/pages/docs/materia/09-smart-contracts/index.js" /* webpackChunkName: "component---src-pages-docs-materia-09-smart-contracts-index-js" */),
  "component---src-pages-docs-materia-10-api-01-overview-md": () => import("./../../../src/pages/docs/materia/10-API/01-overview.md" /* webpackChunkName: "component---src-pages-docs-materia-10-api-01-overview-md" */),
  "component---src-pages-docs-materia-10-api-02-entities-md": () => import("./../../../src/pages/docs/materia/10-API/02-entities.md" /* webpackChunkName: "component---src-pages-docs-materia-10-api-02-entities-md" */),
  "component---src-pages-docs-materia-10-api-03-queries-md": () => import("./../../../src/pages/docs/materia/10-API/03-queries.md" /* webpackChunkName: "component---src-pages-docs-materia-10-api-03-queries-md" */),
  "component---src-pages-docs-materia-10-api-index-js": () => import("./../../../src/pages/docs/materia/10-API/index.js" /* webpackChunkName: "component---src-pages-docs-materia-10-api-index-js" */),
  "component---src-pages-docs-materia-12-proposals-01-overview-md": () => import("./../../../src/pages/docs/materia/12-proposals/01-overview.md" /* webpackChunkName: "component---src-pages-docs-materia-12-proposals-01-overview-md" */),
  "component---src-pages-docs-materia-12-proposals-02-updating-contracts-md": () => import("./../../../src/pages/docs/materia/12-proposals/02-updating-contracts.md" /* webpackChunkName: "component---src-pages-docs-materia-12-proposals-02-updating-contracts-md" */),
  "component---src-pages-docs-materia-12-proposals-03-change-fees-md": () => import("./../../../src/pages/docs/materia/12-proposals/03-change-fees.md" /* webpackChunkName: "component---src-pages-docs-materia-12-proposals-03-change-fees-md" */),
  "component---src-pages-docs-materia-12-proposals-index-js": () => import("./../../../src/pages/docs/materia/12-proposals/index.js" /* webpackChunkName: "component---src-pages-docs-materia-12-proposals-index-js" */),
  "component---src-pages-docs-materia-index-md": () => import("./../../../src/pages/docs/materia/index.md" /* webpackChunkName: "component---src-pages-docs-materia-index-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lm-js": () => import("./../../../src/pages/lm.js" /* webpackChunkName: "component---src-pages-lm-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-pool-js": () => import("./../../../src/pages/pool.js" /* webpackChunkName: "component---src-pages-pool-js" */),
  "component---src-pages-soon-js": () => import("./../../../src/pages/soon.js" /* webpackChunkName: "component---src-pages-soon-js" */),
  "component---src-pages-strategy-js": () => import("./../../../src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */),
  "component---src-pages-swap-js": () => import("./../../../src/pages/swap.js" /* webpackChunkName: "component---src-pages-swap-js" */)
}

